interface IMenu {
  menu: Menu;
}
interface Menu {
  path: string;
  displayName: string;
  icon: string;
}

export const MENUS: IMenu[] = [
  {
    'menu': { path: '/dashboard', displayName: 'Dashboard', icon: 'dashboard' },
  },
  {
    'menu': { path: '/support', displayName: 'Support', icon: 'live_help' },
  },
];

