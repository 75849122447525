export class User {
  id: string;
  userid: string;
  password: string;
  firstname: string;
  lastname: string;
  email: string;
  usertype: string;

  getUserType(): string {
    return this.usertype;
  }
  setUserType(iusertype): void {
    this.usertype = iusertype;
  }

}
