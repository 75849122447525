// Toggle between http and https depending if ssl key is available
const DEFAULT_PROTOCOL = 'https';
export const build = {
  API_HOST: DEFAULT_PROTOCOL + '://api.govcloud-stage.ubicquia.com/api/',
  SITE_URL: 'https://swagger.govcloud-stage.ubicquia.com/',
  SITE_URL_HOST: 'swagger.govcloud-stage.ubicquia.com',
  LOGO: 'logo',
  // Options: en (English)
  DEFAULT_LANG: 'en',
  keycloak: {
    // Url of the Identity Provider
    issuer: 'https://auth.govcloud-stage.ubicquia.com/auth/realms/ubivu-stage',

    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://swagger.govcloud-stage.ubicquia.com/',

    // The SPA's id. 
    // The SPA is registerd with this id at the auth-serverß
    clientId: 'swagger.ubivu.app.admin.client',

    dummyClientSecret: 'e6c4b590-1937-4289-8bc2-16ae4ddaa7e8',

    responseType: 'code',
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC.
    scope: 'openid profile email offline_access',
    
    // Remove the requirement of using Https to simplify the demo
    // THIS SHOULD NOT BE USED IN PRODUCTION
    // USE A CERTIFICATE FOR YOUR IDP
    // IN PRODUCTION
    requireHttps: false,
    // at_hash is not present in JWT token
    showDebugInformation: true,
    disableAtHashCheck: true
  }
};
