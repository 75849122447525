import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRoutingModule } from './page-routing.module';
import { PageComponent } from './page.component';
import { MaterialModule } from '../shared/material.module';
import { AppTranslationModule } from 'src/app/app.translation.module';

@NgModule({
  declarations: [PageComponent],
  imports: [
    CommonModule,
    PageRoutingModule,
    SharedModule.forRoot(),
    MaterialModule,
    AppTranslationModule,
  ],
})
export class PageModule { }
