import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/US/', '.json');
}

const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient],
  },
};

@NgModule({
  imports: [TranslateModule.forRoot(translationOptions)],
  exports: [TranslateModule],
  providers: [TranslateService],
})
export class AppTranslationModule {
  constructor(private translate: TranslateService,
    private storageService: StorageService) {
      translate.addLangs(['en']);
      const lang = this.storageService.getStorageItem('lang');
      // Default Language is set in env file, and defaults to English if not set
      const defaultLang = environment.DEFAULT_LANG || 'en';
      if (lang) {
        translate.setDefaultLang(lang);
        translate.use(lang);
      } else {
        translate.setDefaultLang(defaultLang);
        translate.use(defaultLang);
      }
  }

  setTranslation(lang: string) {
    this.storageService.store('lang', lang);
    this.translate.use(lang);
  }
}
