import { Environment } from "./environment.model";
import { build } from "./build";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: Environment = {
  production: false,
  DEFAULT_LANG: build.DEFAULT_LANG,
  API_HOST: build.API_HOST,
  API_SWAGGER: build.API_HOST + 'roleBaseSwaggerDoc',
  API_LOGIN: build.API_HOST + 'loginToApi',
  API_KEYCLOAK_LOGIN: build.API_HOST + 'saveLoginDetail',
};
