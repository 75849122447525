import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ubiVU-swagger-panel';
  class = 'hide';
  ngOnInit() {
    this.loadData();
  }
  constructor(private loginState: ApiService) {}
  loadData() {
    this.loginState.isLoggedInState.subscribe(async value => {
      this.class = await value;
    });
  }
}

