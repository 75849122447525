import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _http: HttpClient) { }

  post(url: string, data: any): Observable<any> {
    return this._http.post(url, data);
  }
  public isLoggedInState: BehaviorSubject<string> = new BehaviorSubject<string>(
    'hide',
  );
  public isLogInProcess: BehaviorSubject<string> = new BehaviorSubject<string>(
    'show',
  );
}

