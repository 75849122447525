import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { AuthService } from './../../page/auth/services/auth.service';
import { User } from '../classes/user';
import { Subject } from 'rxjs';

@Injectable(
)
export class AuthConfigService {
    
    private _decodedAccessToken: any;
    private _decodedIDToken: any;
    private _refreshToken: any;
    get decodedAccessToken() { return this._decodedAccessToken; }
    get decodedIDToken() { return this._decodedIDToken; }
    get refreshToken() { return this._refreshToken; }

    private get _router() { return this.injector.get(Router); }
    private get authService() { return this.injector.get(AuthService); }

    constructor(
      private readonly oauthService: OAuthService,
      private readonly authConfig: AuthConfig,
      private storageService: StorageService,
      private translate: TranslateService,
      private injector: Injector
    ) {
    }

    

    async initAuth(): Promise<any> {
      return new Promise((resolveFn, rejectFn) => {
        // setup oauthService
        this.oauthService.configure(this.authConfig);
        this.oauthService.setStorage(localStorage);
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
  
        // subscribe to token events
        this.oauthService.events.pipe(filter((e: any) => {
            return e.type === 'token_received';
        })).subscribe(() => this.handleNewToken());
     
        // continue initializing app or redirect to login-page
        this.oauthService.loadDiscoveryDocumentAndLogin().then(isLoggedIn => {
          if (isLoggedIn) {
            this.oauthService.setupAutomaticSilentRefresh();
            resolveFn(true);
          } else {
            this.oauthService.initImplicitFlow();
            // rejectFn();
          }
        });
      });
    }
  
    private handleNewToken() {
      this._decodedAccessToken = this.oauthService.getAccessToken();
      this._decodedIDToken = this.oauthService.getIdToken();
      this._refreshToken = this.oauthService.getRefreshToken();

      const params = {
        'access_token' : this._decodedAccessToken,
        'refresh_token' : this._refreshToken,
        'id_token' : this._decodedIDToken
      }

      this.authService.loginUsingKeycloak(params)
          .pipe().subscribe(
                    data => {
                        if (data['status'].includes('success')) {
                          const user = new User();
                          const userData = data['data'];
                          user.id = userData.id;
                          user.userid = userData.id;
                          user.firstname = userData.firstname;
                          user.lastname = userData.lastname;
                          user.email = userData.email;
                          user.usertype = userData.role;
                      
                          this.storageService.store('user', user);
                          this.storageService.store('isLoggedin', true);
                          this.storageService.store('accessToken', this._decodedAccessToken);

                          this._router.navigate(['pages/swagger']);
                        } 
                    }, err => {
        });
  }

}